import { Component, Vue } from 'vue-property-decorator';
import { ApiResource } from '@/types/resources/ApiResource';

@Component
export default class ResourceInfoMixin extends Vue {
  get resourceTitleFromRoute() {
    return this.$route.params.resourceTitle;
  }

  get resourceIdFromRoute() {
    return this.$route.params.resourceId;
  }

  getResourceTitleFromResource(resource: ApiResource) {
    return resource['@context'].split('/').reverse()[0];
  }
}
